import {
  OrgCoupon,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceId,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  PlayerBundleId
} from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit, SendMessageToSlackChannel } from "../../utils";
import { BatchTask } from "@ollie-sports/firebase";
import { triggerForPaymentNotifications } from "../notification/payment-helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";
import { olliePipe__server__sendOlliePipeEvent } from "../olliePipe";

export async function orgInvoice__server__updateAmountInvoiced(p: { orgInvoiceId: OrgInvoiceId; newAmountCents: number }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const orgInvoice = await h.OrgInvoice.getDoc(p.orgInvoiceId);

  if (!orgInvoice) {
    return { status: "error" };
  }
  const oldAmountCents = orgInvoice.amountDueCents;

  const parentInvoiceId = "parentOrgInvoiceId" in orgInvoice ? orgInvoice.parentOrgInvoiceId : orgInvoice.id;

  const [parentInvoice, childrenInvoices] = await Promise.all([
    parentInvoiceId !== orgInvoice.id ? h.OrgInvoice.getDoc(parentInvoiceId) : orgInvoice,
    h.OrgInvoice.query({
      where: [{ parentOrgInvoiceId: ["==", parentInvoiceId] }, { orgId: ["==", orgInvoice.orgId] }]
    }).then(a => a.docs as OrgInvoiceChild[])
  ]);

  if (!parentInvoice) {
    return { status: "error" };
  }

  const nowMS = Date.now();

  // Update the Invoice
  try {
    await h._BatchRunner.executeBatch([
      await h.OrgInvoice.update(
        {
          id: orgInvoice.id,
          doc: {
            amountDueCents: p.newAmountCents,
            thisInvoicePaidInFullDateMS:
              orgInvoice.derivedTotalAmountPaidCentsBeforeAllFees >= p.newAmountCents ? nowMS : undefined
          }
        },
        { returnBatchTask: true }
      ),
      await h.OrgInvoice.update(
        {
          id: parentInvoice.id,
          doc: {
            derivedTotalAmountDueCentsIncludingChildrenInvoices:
              [parentInvoice, ...childrenInvoices]
                .filter(a => a.id !== p.orgInvoiceId)
                .map(a => a.amountDueCents)
                .reduce((a, b) => a + b, 0) + p.newAmountCents
          }
        },
        { returnBatchTask: true }
      )
    ]);
  } catch (e) {
    return { status: "error" };
  }

  await triggerForPaymentNotifications({
    type: "updatedInstallmentAmount",
    orgInvoice,
    oldAmountCents,
    newAmountCents: p.newAmountCents
  }).catch(e => {
    olliePipe__server__sendOlliePipeEvent({
      type: "error-update-invoice-amount-notification",
      payload: e,
      slackImportantErrorsMessage: `Error running error-update-invoice-amount-notification. ${JSON.stringify(
        {
          message: e.message,
          stack: e.stack
        },
        null,
        2
      )}`
    });
  });

  return { status: "success" };
}

orgInvoice__server__updateAmountInvoiced.auth = async (r: express.Request) => {
  // Make sure user has correct permission
  await validateToken(r);
};

// i18n certified - complete
